<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/warehouses"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
            {{$t('new_warehouse')}}
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">{{$t('edit_warehouse')}}</div>
        </div>
      </div>
        <div class="bg-white rounded-lg p-6 pt-2">
          <div class="grid lg:grid-cols-2 gap-6">
             <div class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                         <label for="name" class="bg-white text-gray-700 px-1">
                     {{$t('name')}} <span :class="$colors.required">*</span></label
                  >
                      </p>
                    </div>
                    <p>
                     <input
                      id="name"
                      :placeholder="$t('warehouse_name')"
                      tabindex="0"
                      type="text"
                      v-model="formData.name"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
                    </p>
              </div>
                <div
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="country" class="bg-white text-gray-700 px-1"
                    >{{$t('country')}}</label
                  >
                </p>
              </div>
              <p>
                <v-select
                  @input="setSelectedContry"
                  v-model="formData.country"
                  :options="options"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="$f.getCountryCode(option.code)"
                    />
                    {{ option.name }} 
                  </template>
                </v-select>
              </p>
            </div>
            <!--   <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="zone" class="bg-white text-gray-700 px-1"
                        >{{$t('zone')}}<span :class="$colors.required">
                          *</span
                        ></label
                      >
                    </p>
                  </div>
                  <div
                    id="city"
                    class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
                  >
                    <div class="w-full">
                      <div class="flex flex-col  relative">
                        <div class="items">
                            <label v-for="(item,index) in zones" :key="index" class="inline-flex items-center">
                               <input :id="item._id" @change='getRowDetail(item._id)' type="checkbox" :class="'rowCheckboxZone-'+item._id" class="form-checkbox h-5 w-5 text-green-600"><span class="ml-2 font-medium text-gray-800">{{item.name}}</span>
                            </label>
                        </div>
                       
                      </div>
                    </div>
                  </div>-->
           </div> 
          <div class="grid lg:grid-cols-2 gap-6 pt-10">
            <div class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                         <label for="parent" class="bg-white text-gray-700 px-1">
                   Parent warehouse</label
                  >
                      </p>
                    </div>
                    <p>
                    <v-select
                     :disabled="(formData._id && formData.is_primary)||!formData.country"
                      @input="setSelectedParent"
                      v-model="formData.parent"
                      :options="warehouses"
                      label="countryName"
                  >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="option.flag"
                    />
                    {{ option.countryName }}
                  </template>
                </v-select>
                    </p>
              </div>
             <div v-if="!formData.parent" class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                         <label for="currency" class="bg-white text-gray-700 px-1">
                     {{$t('currency')}} <span :class="$colors.required">*</span></label
                  >
                      </p>
                    </div>
                    <p>
                     <input
                      id="currency"
                      :placeholder="$t('warehouse_currency')"
                      tabindex="0"
                      type="text"
                      v-model="formData.currency"
                      class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
                    </p>
              </div>
             

              <div v-if="formData.parent" class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                         <label for="Zones" class="bg-white text-gray-700 px-1">
                     {{$t('Zones')}} <span :class="$colors.required">*</span></label
                  >
                      </p>
                    </div>
                    
                    <p>
                      <v-select label="name" v-model="formData.zones"  :options="zones" multiple ></v-select>
                    </p>
              </div>

          </div>  
          <div v-if="formData._id && !formData.parent" class="flex mt-10 mb-5 items-center text-center">
            <hr class="border-gray-300 border-1 w-full rounded-md">
            <label class="block font-medium font-medium text-base cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Call Center settings</span></label>
            <hr class="border-gray-300 border-1 w-full rounded-md">
          </div>
          <div  v-if="formData._id && !formData.parent" class="grid lg:grid-cols-2 gap-6">

            

            <div class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="country" class="bg-white text-gray-700 px-1">Traitement des Orders <span :class="$colors.required">*</span></label>
                </p>
              </div>
             
                <div class="flex flex-wrap">
                  <div  class="w-full">
                    <div class="flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded">
                          <v-select @input="setSelectedStatus" multiple placeholder="Type orders ..."  v-model="formData.callcenter" label="name" :options="params_callcenter" class="capitalize status-war rounded rounded-r-none flex-shrink border border-black flex-grow flex-auto leading-normal w-px flex-1 border-0  border-grey-light self-center relative outline-none"></v-select>
                        <!-- <v-select placeholder="Sort by date ..."  v-model="sortData" label="name" :options="sort_callcenter" class="flex capitalize -mr-px w-1/2 border border-black items-center leading-normal flex-1 border-0  border-grey-light rounded rounded-l-none px-3 self-center relative outline-none"></v-select> -->
                    </div>
                  </div>
              </div>
              <div v-if="formData.callcenter.length>1" class="flex w-full items-center p-1 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                  <svg class="flex-shrink-0 inline w-4 h-4 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"></path>
                  </svg>
                  <span class="sr-only">Info</span>
                  <div>
                    Les commandes sont traitées dans cet ordre: <small><b>{{ formData.callcenter.map(e=> e.name).join(' > ') }}</b></small>
                  </div>
              </div>  
            </div>

            <div
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="country" class="bg-white text-gray-700 px-1">Pending Unreached</label>
                </p>
              </div>
              <p @click="$vm2.open('myModalSettings')"> 
                  <input type="text" class="form-control-3"  :placeholder="`Traits ${formData.pending_unreached.nb_perDay} fois par jour pendant ${formData.pending_unreached.nb_days} jours`" disabled="">
              </p>
            </div>
               
           </div> 
            <div class="mt-6 pt-3 flex justify-center">
              <button
                @click="save"
                class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              >
                {{$t('save')}}
              </button>
            </div>
     
        </div>
    </div>
    <vue-modal-2 v-if="formData._id && !formData.parent" name="myModalSettings" :headerOptions="{title: 'Pending Unreached'}" :noFooter="true" modalSize="md">
            <div class="flex flex-col w-full h-auto py-5 px-2">
                <!-- Header -->
                <!-- Modal Content-->
                <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <div class="bg-white rounded px-2  pb-2 flex flex-col">
                  <div v-if="!formData.parent" class="grid lg:grid-cols-2 gap-6">
                        <div
                          class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                        >
                          <div
                            class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                          >
                            <p>
                              <label for="country" class="bg-white text-gray-700 px-1">Nombre de fois par jour</label>
                            </p>
                          </div>
                          <p>
                            <input id="xcxcxc" autocomplete="false" tabindex="0" type="number" min="1" v-model="formData.pending_unreached.nb_perDay" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                          </p>
                        </div>
                        <div
                          class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
                        >
                          <div
                            class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                          >
                            <p>
                              <label for="country" class="bg-white text-gray-700 px-1">Pendant</label>
                            </p>
                          </div>
                          <p>
                            <input id="cxcxcswc" autocomplete="false" tabindex="0" type="number" min="1" v-model="formData.pending_unreached.nb_days" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                          </p>
                        </div>
               
                 </div> 
                    <div class="flex p-2 mt-4">
                          <div class="flex-auto flex flex-row-reverse">
                              <button :disabled="loading" @click="CloseModal('myModalSettings','confirm')" class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"> Confirm</button>
                              <button :disabled="loading"  @click="CloseModal('myModalSettings','close')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none">Annuler</button>
                          </div>
                      </div>
                </div>
                <!-- End of Modal Content-->
          </div>
    </vue-modal-2>

      <!-- Modal CONFIRM LIVREUR STATUS-->
      <vue-modal-2 name="modalConfirm" :headerOptions="{title: `Confirm date Status: ${params_callcenter_selected.status}`}" :noFooter="true" modalSize="md">
                <div class="flex flex-col w-full h-auto">
                <!-- Header -->
                <div class="bg-white rounded px-2  pb-2 flex flex-col">
                    <p class="font-bold text-center">Veuillez sélectionner propriétés par date  📣</p>
                    <div class="p-5 pb-2">
                        <div class="border border-gray-700 animation-pulse   w-full h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                <p>
                                    <v-select   placeholder="Sort by date ..."  v-model="sortData" label="name" :options="sort_callcenter" ></v-select>
                                </p>
                         </div>
                    </div>
                    
                    <div class="flex p-2 mt-4">
                        <div class="flex-auto flex flex-row-reverse"> 
                            <button  @click="ConfirmStatus" class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"> Confirm </button>
                            <!-- <button :disabled="loading"  @click="CloseModal('modalConfirm')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none"> Annuler</button> -->
                        </div>
                    </div>
                </div>
               </div>
            </vue-modal-2>
         <!-- End Modal CONFIRM LIVREUR STATUS -->

  </div>
  
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        callcenter:[],
        pending_unreached:{nb_perDay:3,nb_days:4}
      },
      options: this.$countries,
      selectContry:"",
      params_callcenter:[{name:'Pending fresh',sort:"-1",key:'pending fresh'},{name:'A rappeler',sort:"-1",key:'pending remind'},{name:'Pending unreached',sort:"-1",key:'pending unreached'}],
      sort_callcenter:[{name:"Nouvelle date",sort:"-1"},{name:"Ancienne date",sort:"1"}],
      sortData:{name:"Nouvelle date",sort:"-1"},
      zones:[],
      selectContryParent:null,
      warehouses:[],
      loading:false,
      fullPage: false,
      params_callcenter_selected:{index:0,status:""}
    };
  },
  watch: {
   
   'formData.pending_unreached': {
     handler(val){
      console.log('oldVal---',val)
     },
     deep: true
  }
  },
  async mounted() {
    await this.getUser();
  //  await this.getZones();
    this.dataToEdit();
    await this.getWarehouses();
  },
  methods: {
    ConfirmStatus(){
      console.log('sort data----',this.sortData)
      let dataSelected= this.formData.callcenter[this.params_callcenter_selected.index];
      dataSelected.sort=this.sortData.sort;
      //remove entre parantaise ()
      dataSelected.name=dataSelected.name.replace(/["'(].*["')]/, '');
      dataSelected.name=dataSelected.name+'('+this.sortData.name+')';
      this.$vm2.close('modalConfirm');
    },
    setSelectedStatus(status){
       if(status){
        console.log('id----------',status)
        console.log('callcenter----------',this.formData.callcenter)

          this.params_callcenter_selected.index=this.formData.callcenter.length-1;
          if(this.formData.callcenter.length !== 0){
            
            //set same name in same element selected
            this.formData.callcenter.forEach(elem => {
              if(elem.key === status[status.length-1].key){
                elem.name= this.formData.callcenter.filter(item=> item.key === status[status.length-1].key)[0].name;
              }
            });
           
            //check duplicated element by key selected
            const ids = this.formData.callcenter.map(({ key }) => key);
            const filtered = this.formData.callcenter.filter(({ key }, index) => !ids.includes(key, index + 1));
            this.formData.callcenter=filtered;

            if(this.formData.callcenter.length === status.length){
              this.params_callcenter_selected.status=this.formData.callcenter[this.formData.callcenter.length-1].name;
              this.$vm2.open('modalConfirm');
            }else return false
            
          } 
           
       }
    },
    async CloseModal(id,action){
     console.log('id----------',id)
     console.log('action----------',action)
      if(action === 'close'){
        this.loading=true;
        const editData = await this.$server.get("warehouses", {id: this.$route.params.id,all:true});
        this.loading=false;
        if(editData.content && editData.content.pending_unreached) this.formData.pending_unreached=editData.content.pending_unreached;
        else this.formData.pending_unreached={nb_perDay:3,nb_days:4}
      }

      this.$vm2.close(id);
    },
    async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res && res.content.results) {
            this.warehouses = res.content.results;
        } else this.warehouses = [];
    },
    setSelectedContry(contry){
      if(contry){
        this.formData.country=contry.name;
        this.formData.countryName=contry.name;
        this.selectContry=contry.code;
      }
    },
    async setSelectedParent(contry) {
       if(contry){
         this.formData.parent=contry;
         this.selectContryParent=contry.code;
         await this.getZones(contry.country);
       }else {this.formData.parent=null;this.formData.zones=[];}
    },
    async save() {
      if (this.formData._id) {
          this.formData.country=this.selectContry;
          if(this.checkData()) {
              this.formData.flag = this.$f.getCountryCode(this.selectContry);
              this.formData.callcenter.sort=this.sortData.sort;
              const data = await this.$server.update("warehouses", this.formData);
              this.formData.country=this.$f.getCountryNameByCode(this.options,this.selectContry);
              if (data._id) {
                alert(this.$t('warehouse_updated'), "success");
              }else alert(data ,"warning");
          }else alert(this.$t('all_required_field'), "warning");
        
      } else {
          if (this.checkData()) {
              this.formData.country=this.selectContry;
              this.formData.flag = this.$f.getCountryCode(this.selectContry);
              this.formData.callcenter.sort=this.sortData.sort;
              const data = await this.$server.create("warehouses", this.formData);
              this.formData.country=this.$f.getCountryNameByCode(this.options,this.selectContry);
              if (data._id) {
                alert(this.$t('warehouse_created'), "success");
                this.formData.name= this.formData.country =this.selectContry= "";
              }else alert(data ,"warning");
          } else alert(this.$t('all_required_field'), "warning");
      }
    },
    emtyTabId(){
      let columns = document.querySelectorAll('.form-checkbox');
      columns.forEach(column => {column.checked = false});
      delete formData.zone;
    },
     async getRowDetail(id) {
        // let rows = this.selectedRows;
        //console.log('.rowCheckboxZone-'+id)
        let columns = document.querySelectorAll('.form-checkbox');
        let element = document.querySelector('.rowCheckboxZone-'+id);
        columns.forEach(column => {column.checked = false});
        element.checked = true;
        this.formData.zone=id;
       // console.log('formdate______________',this.formData)
     
    },
    checkData() {
      if (
        this.formData.name &&
        this.formData.country 
      ) {
        return true;
      } else {
        return false;
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      //console.log(this.currentUser);
    },
    async getZones(country) {
      console.log('country-----',country)
     const zones = await this.$server.search("zones", {limit:40,country:country});
     if(zones && zones.content.results){
       this.zones=zones.content.results;
     }else  this.zones=[];
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("warehouses", {
          id: this.$route.params.id,all:true
        });
        this.formData = editData.content;
        console.log('formData-------',this.formData)
        this.selectContry=this.formData.country;
        this.formData.country=this.$f.getCountryNameByCode(this.options,this.formData.country);
        if(!this.formData.callcenter) this.formData.callcenter={}
        else{
          if(this.formData.callcenter && this.formData.callcenter.name){
            if(this.formData.callcenter.sort.toString() === '-1')  this.sortData={name:"Nouvelle date",sort:"-1"};
            else this.sortData={name:"Ancienne date",sort:"1"};
           }
        }

       

        if(!this.formData.pending_unreached) this.formData.pending_unreached={nb_perDay:3,nb_days:4}
        if(this.formData.parent) await this.getZones(this.formData.parent.country);
        //  let columns = document.querySelectorAll('.form-checkbox');
        // let element = document.querySelector('.rowCheckboxZone-'+editData.content.zone);
        // columns.forEach(column => {column.checked = false});
        // element.checked = true;
      }
    },
    /*************************************************************** */
    /**************************************************************/
  },
};
</script>

<style scoped>
 .form-control-3 {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    cursor: pointer;
    background-color: #eee;
    opacity: 1;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.items {
    display: flex;
    flex-direction: column;
    padding: 10px;
    counter-reset: done-items undone-items;
}
/* .items input {
    display: block;
    height: 53px;
    margin: 0 0 -53px -9999px;
    outline: none;
    counter-increment: done-items;
} */
.items input:checked + label {
    -webkit-animation: done .5s;
    animation: done .5s;
}
.items label{
  position: relative;
  display: block;
  position: relative;
  padding: 15px 0 15px 15px;
  border-top: 1px dashed #e2e8f0;
  cursor: pointer;
  -webkit-animation: undone .5s;
  animation: undone .5s;
}
.items label:first-child{
  border-top:inherit
}
.items label i {
    display: block;
    position: absolute;
    top: 11px;
    left: 10px;
}
.flag-img {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: text-top;
  line-height: 16px;
  margin-right: 5px;
}
.tag_cities {
  width: 100%;
  max-width: 100%;
}
.vs__dropdown-toggle {
  border: none;
}
.tag_cities .tag {
  background: rgb(49 130 206 / 16%);
  border-radius: 20px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 28px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  padding-right: 0;
}
.vs-chip--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10px;
}
.vs-chip--close {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tag_cities .tag [data-role="remove"]::after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}
.tag_cities .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.top-100 {
  top: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.max-h-select {
  max-height: 300px;
}
</style>